.containerTextArea {
  overflow-Y: auto;
  max-height: 180px;
  color: #fff;
  word-wrap: break-word;
  margin-top: 10px; }

.containerButton {
  display: flex;
  justify-content: center;
  align-items: center; }

.text {
  margin-right: 10px; }

.teleprompt {
  white-space: pre-line; }
