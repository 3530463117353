.base {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 200;
  transform: translate(-50%, 0);
  padding: 15px 20px;
  margin-top: 10px;
  background: var(--deep-blue);
  border-radius: 6px;
  color: #FFF; }

.tooltip {
  composes: base;
  max-width: 350px;

  @media (max-width: 767px) {
    max-width: 355px; }

  @media (max-width: 375px) {
    max-width: calc(100vw - 20px); } }

.tourTooltip {
  composes: base;
  padding: 20px;

  .footer {
    display: block;
    margin-top: 15px;
    text-align: right; } }

.white {
  composes: base;
  padding: 25px;
  background: #FFF;
  color: var(--grayish-brown);

  .arrow {
    border-bottom-color: #FFF; } }

.tourModal {
  composes: white;

  .footer {
    display: block; } }

.welcome {
  composes: white;
  background: #f8faf9;
  width: 100%;
  max-width: 730px;
  padding: 0; }

.filter {
  composes: welcome;
  background: #FFF; }

.error {
  composes: white; }

.arrow {
  display: none;
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -7px;
  border: solid transparent;
  border-width: 0 7px 7px 7px;
  border-bottom-color: var(--deep-blue); }

.overlay {
  position: fixed;
  top: -200px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--deep-blue-rgb), 0.7);
  z-index: 200; }

.loader {
  position: fixed;
  z-index: 201;
  top: 50%;
  left: 50%;
  padding: 2px;
  background: #FFF;
  transform: translate(-50%, -50%);
  font-size: 0; }

.footerContainer {
  text-align: center; }

.footer {
  display: inline-block; }

// direction

.direction-up {
  transform: translate(-50%, -100%);
  margin-top: -10px;

  .arrow {
    top: 100%;
    margin-top: 0;
    border-width: 7px 7px 0 7px;
    border-top-color: var(--deep-blue);
    border-bottom-color: transparent; } }

// offset

.offset-0 {
  margin-top: 0; }

// centered

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 90vh;
  margin: 0;
  transform: translate(-50%, -50%);
  overflow: auto;

  @media (max-width: 767px) {
    top: 50%;
    right: 0;
    left: 0;
    max-width: 355px;
    margin: auto;
    transform: translate(0, -50%); }

  @media (max-width: 375px) {
    max-width: calc(100vw - 20px); } }

.templateTopic {
  position: fixed;
  z-index: 200;
  background: var(--white);
  color: var(--grayish-brown);
  border-radius: 0 6px 6px 0;
  min-width: 532px;
  max-width: 532px;
  min-height: 80%;
  max-height: 90vh;
  top: 16px;
  left: 0;
  padding: 0;
  overflow-y: auto;

  @media (max-height: 900px) {
    min-width: 80%;
    max-width: 80%;
    overflow-y: auto; }

  @media (max-width: 767px) {
    min-width: 100%;
    max-width: 100%;
    top: 0; }

  @media (max-width: 1024px) {
    min-width: 100%;
    max-width: 100%;
    top: 0; } }

.editTopic {
  composes: white;
  min-width: 50%;
  padding: 0; }

.founderOnboarding {
  composes: tourModal;
  width: 100%; }

.firstTime {
  composes: base;
  background: var(--green);
  color: black;

  .footer {
    text-align: right; }

  .arrow {
    border-bottom-color: var(--green); } }

.footer-container-firstTime {
  text-align: right; }

.videoBuilderTour {
  composes: white;

  .footer {
    display: inherit; } }
