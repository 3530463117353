.full {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  padding: 100px 15px 10px;
  background: linear-gradient(rgba(20, 33, 44, 0), var(--dark-blue)); }

.mini {
  composes: full;
  @media (max-width: 767px) {
    padding: 0; } }

.micro {
  composes: full;
  padding: 5px 15px 5px 5px;

  .main {
    margin-right: 0; } }

.main {
  display: flex;
  align-items: center;
  margin-right: 15px; }

.segmentAndProgressbar {
  flex: 1; }

.progressbar {
  position: relative;
  padding: 5px 0 20px;
  height: 14px;

  &:before {
    content: " ";
    display: block;
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2px; } }

.progress {
  position: absolute;
  top: 5px;
  left: 0;
  width: 0;
  height: 4px;
  background: #FFF;
  border-radius: 2px; }

.time {
  margin-left: 10px;
  padding-bottom: 12px;

  @media (max-width: 767px) {
    margin: 0; } }

.side {
  margin-left: 15px;

  @media (max-width: 767px) {
    display: flex;
    margin: 0; } }
