.container {
  // max-width: 1370px
  padding: 0;
  margin: 0 auto;
  height: 100%; }

.topicsContainer {
  height: 100vh;
  padding: 10px 20px; }

.btnExit {
  text-align: right;
  padding: 10px;
  z-index: 999; }

.topics {
  width: 300px;
  margin-right: 25px; }

.recorder {
  height: 100vh; }

// .recorderInner
//   height: 100vh

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 20px; }

.prevNextButtons {
  display: grid;
  grid-gap: 15px;
  grid-auto-flow: column; }

.goToTopics {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 999;
  padding: 15px;
  right: 0; }

@media (max-width: 1025px) {
  .topicsAndRecorder {
    flex-direction: column; }

  .topics {
    order: 2;
    width: auto;
    margin: 20px 0 0; } }

.containerTotalLength {
  margin-left: 8px;
  margin-bottom: 25px; }

.totalLength {
  font-size: 15px;
  font-style: italic; }

.hidden {
  visibility: hidden; }
