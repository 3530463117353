.container {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  will-change: transform; } // fix rounded corners in Safari

.btnPreview {
  padding: 25px 25px 0 0;
  text-align: right; }

.buildButton {
  padding-top: 1em; }
